import { Error, errorData, getErrorData } from '@/api/error'
import toast from '@/lib/toast'
import { useModalStore } from '@/lib/useModalStore'
import {
  MutationFunction,
  QueryKey,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { signOut } from 'next-auth/react'
import { useShallow } from 'zustand/react/shallow'

export const useError = () => {
  const { openModal, closeModal, list } = useModalStore(
    useShallow((state) => ({
      list: state.list,
      openModal: state.openModal,
      closeModal: state.closeModal,
    })),
  )

  const open = (
    error: Error,
    callback?: () => void,
    closeCallback?: () => void,
  ) => {
    const errorInfo = errorData[error.errorCode]

    const message = errorInfo?.message || error?.message

    const openedModal = list.find((modal: any) => modal.key === error.errorCode)
    if (openedModal) return

    if (message) {
      openModal({
        key: `${error.errorCode}`,
        message: message,
        onOk: () => {
          if (callback) callback()
          else closeModal()
        },
        onCancel: () => {
          if (closeCallback) closeCallback()
        },
      })
    }
  }

  return {
    open,
  }
}

interface RequestData {
  mutationFn?: MutationFunction<any, any> | undefined
  mutationKey?: string[]
  invalidateQueryKey?: QueryKey
  onMutate?:
    | ((variables: any) => void | Promise<void | undefined> | undefined)
    | undefined
  onSuccess?:
    | ((
        data: any,
        variables: any,
        context: void | undefined,
      ) => void | Promise<unknown>)
    | undefined
  onError?:
    | ((
        error: any,
        variables: any,
        context: void | undefined,
      ) => void | Promise<unknown>)
    | undefined
  onNetworkError?:
    | ((
        error: any,
        variables: any,
        context: void | undefined,
      ) => void | Promise<unknown>)
    | undefined
}

export const useRequest = (requestData: RequestData) => {
  const queryClient = useQueryClient()
  // const { data: session } = useSession()
  // console.log('#session', session)

  const { open } = useError()
  const result = useMutation({
    // mutationKey: requestData.mutationKey,
    mutationFn: requestData.mutationFn,
    throwOnError: false,
    onSuccess: (data: any, variables: any, context: any) => {
      if (requestData.onSuccess) {
        requestData.onSuccess(data, variables, context)
      }
      if (requestData.invalidateQueryKey) {
        queryClient.invalidateQueries({
          queryKey: requestData.invalidateQueryKey,
        })
      }
    },
    onError: (error: any, variables: any, context: any) => {
      console.log('#userError onError', error)

      console.log(error.errorCode, variables)

      // onError 구현과 상관없이 VPN오류는 즉시 처리
      if (['INVALID_IP'].includes(error.errorCode)) {
        open(error, () =>
          signOut({
            callbackUrl: '/login',
          }),
        )
        return
      }

      if (error.errorCode === 'OFFLINE_NETWORK') {
        requestData.onNetworkError
          ? requestData.onNetworkError(error, variables, context)
          : toast.error(getErrorData(error.errorCode).message)
        return
      }

      // 이미 토큰만료 (로그아웃) / 혹은 강제 로그아웃 처리된 경우 / 접근 권한 없는 경우
      if (
        [
          'ALREADY_LOGOUT_USER',
          'CANNOT_ACCESS_MANAGER',
          'CANNOT_ACCESS_STAFF',
        ].includes(error.errorCode)
      ) {
        open(error, () => signOut({ callbackUrl: '/login' }))
      }

      if (requestData.onError) {
        requestData.onError(error, variables, context)
      } else if (
        getErrorData(error?.errorCode) === errorData['UNKNOWN_ERROR']
      ) {
        open(error)
      }
    },
  })

  return result
}
